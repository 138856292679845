























































import Vue from 'vue';
import moment from 'moment';
import * as Api from '@/api/activity/distributionManage';
import { dateStringToTimestamp, timestampFormatDate } from '@/utils/index';
import { downloadFile } from '@/utils/index';
import G6 from '@/utils/g6/g6.js';
import * as echarts from 'echarts/core';
import { TooltipComponent } from 'echarts/components';
import { TreeChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import store from '@/store';

echarts.use([TooltipComponent, TreeChart, CanvasRenderer]);
const columns = [
  {
    title: '姓名',
    dataIndex: 'contacts',
    align: 'center',
  },
  {
    title: '登录账号',
    dataIndex: 'account',
    align: 'center',
  },
  {
    title: '手机号码',
    dataIndex: 'inviteeMobile',
    align: 'center',
  },
  {
    title: '所属租户',
    dataIndex: 'agencyName',
    align: 'center',
    scopedSlots: { customRender: 'agencyName' },
  },
  {
    title: '所属企业',
    dataIndex: 'extend1',
    align: 'center',
    scopedSlots: { customRender: 'extend1' },
  },
  {
    title: '用户来源',
    align: 'center',
    dataIndex: 'applySource',
    scopedSlots: { customRender: 'applySource' },
  },
  {
    title: '邀请人手机号',
    dataIndex: 'inviterMobile',
    align: 'center',
    scopedSlots: { customRender: 'inviterMobile' },
  },
  {
    title: '所有下级总数',
    dataIndex: 'allChildrenCount',
    align: 'center',
  },
  {
    title: '下级关系导图',
    dataIndex: 'relationship',
    align: 'center',
    scopedSlots: { customRender: 'relationship' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: '200px',
  },
];
const { token = '' } = store.getters;
const dataSource = [];
export default Vue.extend({
  name: 'dictList',
  data() {
    return {
      tableLoading: false,
      searchForm: {
        inviteeMobile: '',
        contacts: '',
        extend1: '',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      formState: {
        dictName: '',
        dictCode: '',
      },
      dataSource,
      columns,
      enterpriseVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      loading: false,
      enterpriseForm: {
        sequenceNbr: '',
        extend1: '',
      },
      enterpriseRules: {
        extend1: [
          {
            required: true,
            message: '请输入所属企业',
            trigger: 'change',
          },
        ],
      },
      graph: null,
      enterpriseLoading: false,
      selectedRowKeys: [], // Check here to configure the default column
      selected: [], //选中的数据
      allChildrenCount: 0,
      portalRecommendTreeModel: [],
      nowRow: {},
    };
  },
  created() {
    this.handleSubmit();
  },
  methods: {
    moment,
    getCheckboxProps: (record) => ({
      props: {
        disabled: record.invoiceStatus === 1, // Column configuration not to be checked
      },
    }),
    // 查询按钮
    handleSubmit() {
      this.pagination.current = 1;
      this.getDataList();
    },
    // 组合查询表单重置
    handleSearchReset() {
      this.searchForm = {};
      this.pagination.current = 1;
      this.getDataList();
    },
    getDataList() {
      const param = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        ...this.searchForm,
      };
      this.tableLoading = true;
      Api.listPage(param)
        .then((res) => {
          const data = res;
          if (data.status === 200) {
            this.dataSource = res.result.list;
            this.pagination.total = res.result.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    relationshipView(inviteeMobile) {
      window.open(`/relationship?inviteeMobile=${inviteeMobile}&token=${token}`)
    },
    exportButton() {
      Api.listExport(this.searchForm).then((res: any) => {
        downloadFile(res, '云算房活动分销统计表');
      });
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selected = selectedRows;
    },
    enterpriseCancel() {
      this.enterpriseVisible = false;
    },
    enterpriseEdit(row) {
      this.enterpriseForm = {
        sequenceNbr: row.sequenceNbr,
        extend1: row.extend1,
      };
      this.enterpriseVisible = true;
    },
    enterpriseOk() {
      this.$refs.enterpriseFormRef.validate((valid) => {
        if (valid) {
          this.enterpriseLoading = true;
          Api.upExtend1(this.enterpriseForm).then((res) => {
            this.enterpriseLoading = false;
            const data = res;
            if (data.status === 200) {
              this.$message.success('关联成功');
              this.getDataList();
              this.enterpriseCancel();
            }
          });
        }
      });
    },
  },
});
