import http from '@/utils/http';
import request from '@/utils/request';

//  分页
export function listPage(formdata: any): any {
  return http.get(`/portal-resources/1/portalRecommend/queryByPage`, formdata);
}

//  导出
// export function listExport(formdata: any): any {
//   return http.get(
//     `/portal-resources/1/portalRecommend/queryByExport/`,
//     formdata
//   );
// }

// 列表导出实物清单
export function listExport(formdata: any){
  return request({
    url: `/portal-resources/1/portalRecommend/queryByExport`,
    method: 'GET',
    responseType: 'blob',
    params: formdata,
  });
}

//  关系导图
export function relationshipTree(inviteeMobile: any): any {
  return http.get(`/portal-resources/1/portalRecommend/relationshipTree/${inviteeMobile}`);
}

//  关联企业
export function upExtend1(formdata: any): any {
  return http.post(`/portal-resources/1/portalRecommend/upExtend1`, formdata);
}